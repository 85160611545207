import React from 'react'

// Components
import Layout from '../components/layout'
import SEO from '../components/seo'

// Styling
import '../styling/pages/not-found.scss'
import { Link } from 'gatsby'

const NotFoundPage: React.FC = () => {
  return (
    <Layout>
      <SEO title="404: Not found" />
      <div className="not-found-page">
        <h1 className="not-found-page__title">Vi hittade inget</h1>
        <p className="not-found-page__content">
          Du har precis hittat en sida som inte existerar... förlåt.
        </p>
        <Link
          to={'/'}
          className="not-found-page__link"
          title="Gå tillbaka till startsidan"
        >
          Gå till startsidan
        </Link>
      </div>
    </Layout>
  )
}

export default NotFoundPage
